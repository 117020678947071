.form {
    height: 100%;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.form h2 {
    background-color: rgba(0, 0, 0, .55);
    text-align: center;
    color: yellow;
    padding: 2rem;
    margin-bottom: .5rem;
}

.form input {
    display: block;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, .55);
    border: none;
    padding: .5rem 2rem;
    color: white;
    font: inherit;
    outline: none;
    font-size: 1.4rem;
}

.form input {
    margin-bottom: 2px;
}

.form input[type="submit"] {
    width: auto;
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
    background-color: rgba(8, 8, 146);
    cursor: pointer;
}

.form input[type="submit"]:hover {
    color: yellow;
}

.form input::placeholder {
    color: rgba(255, 255, 255, .75);
}

.message {
    position: absolute;
    top: 0;
    left: 1.5rem;
    right: 1.5rem;
    height: 100%;
    background-color: rgba(0, 0, 0, .75);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: yellow;
}

@media only screen and (max-width: 800px) {
    .form {
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .form h2 {
        padding: 1.5rem;
        font-size: 2rem;
    }

    .form input[type="submit"] {
        width: 80%;
    }
}