.date {
    width: 90%;
    margin: 0 auto .5rem;
    background-color: rgba(8, 8, 146, 0.884);
    padding: 1rem .5rem;
    color: yellow;
    height: 7rem;
    box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, .55);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}