/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set font size (1rem = 10px) */
html {
    font-size: 62.5%;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    font-size: 1.5rem;
    line-height: 1;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 2.2rem;
}

/* Remove list styles on ul, ol elements */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Reset buttons */
button {
    border: none;
    outline: none;
    background-color: transparent;
}

@media only screen and (min-width: 1800px) {
  html {
    font-size: 68.75%;
  }
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 50%;
  }
}