.story {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.story h2 {
    background-color: rgba(0, 0, 0, .55);
    text-align: center;
    color: yellow;
    padding: 2rem;
    margin-bottom: .5rem;
}

.text {
    background-color: rgba(0, 0, 0, .55);
    padding: 1.5rem;
    flex-grow: 1;
    overflow: auto;
}

.text p {
    line-height: 1.2;
    color: white;
}

.text p:not(:last-of-type) {
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 800px) {
    .story div {
        padding: 1rem;
    }
}
