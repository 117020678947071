.menu ul {
    display: flex;
    justify-content: space-between;
}

.menu ul li {
    width: 24.8%;
}

@media only screen and (max-width: 600px) {
    .menu ul {
        display: none;
    }
}