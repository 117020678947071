.image {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, .95);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.image span {
    position: absolute;
    top: 3rem;
    right: 3rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    cursor: pointer;
}

.image img {
    object-fit: contain;
    height: 80vh;
    cursor: zoom-out;
}