.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
}

.picture {
    margin-top: 1.5rem;
    background-color: rgba(0, 0, 0, .55);
    padding: .5rem;
    width: 40%;
}

.picture img {
    object-fit: cover;
    width: 100%;
    cursor: zoom-in;
}

@media only screen and (max-width: 800px) {
    .picture {
        width: 60%;
    }
}

@media only screen and (max-width: 600px) {
    .picture {
        width: 80%;
    }
}

@media only screen and (max-width: 400px) {
    .picture {
        width: 90%;
    }
}