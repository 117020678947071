.menu {
    padding: 1.5rem;
}

.menu ul {
    display: flex;
    justify-content: space-between;
}

.menu li {
    width: 32.9%;
}

.menu li:first-of-type {
    display: none;
}

@media only screen and (max-width: 800px) {
    .menu {
        padding: 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .menu {
        position: absolute;
        bottom: 0;
        left: 0;
        height: auto;
        width: 100%;
        transform: translateY(16rem);
        transition: transform .3s;
    }

    .menu ul {
        flex-direction: column;
    }
    
    .menu li {
        width: 100%;
    }

    .menu li:first-of-type {
        display: block;
    }
}