.button {
    display: block;
    margin: 2px auto;
    padding: 1.5rem .5rem;
    color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .55);
    background-color: rgba(8, 8, 146);
    width: 100%;
    text-decoration: none;
    text-align: center;
}

.button--red {
    display: block;
    margin: 2px auto;
    padding: 1.5rem .5rem;
    color: yellow;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .55);
    background-color: red;
    width: 100%;
    text-decoration: none;
    text-align: center;
}

.link {
    color: rgba(255, 255, 0, 0.75);
    font-weight: bold;
    text-decoration: none;
}

.button:hover,
.link:hover {
    color: aqua;
}

.button--red:hover {
    color: white;
}