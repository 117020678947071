.header {
    padding: 1.5rem;
}

.header h1 {
    background-color: red;
    text-align: center;
    color: yellow;
    padding: 2.5rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .55);
    margin-bottom: 1.5rem;
    font-size: 3.5rem;
}

@media only screen and (max-width: 800px) {
    .header {
        padding: 1rem;
    }

    .header h1 {
        padding: 2rem;
        font-size: 3rem;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        grid-column: 2 / -1;
    }

    .header h1 {
        padding: 1.5rem;
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 400px) {

    .header h1 {
        font-size: 2.2rem;
    }
}