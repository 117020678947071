.text {
    padding: 0 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.text h2 {
    background-color: rgba(0, 0, 0, .55);
    text-align: center;
    color: yellow;
    padding: 2rem;
    margin-bottom: .5rem;
}

.container {
    /* height: 95%; */
    flex-grow: 1;
    overflow: auto;
    background-color: rgba(0, 0, 0, .55);
    padding: 1.5rem;
    margin-bottom: .5rem;
}

.text p {
    line-height: 1.2;
    color: white;
}

.text p:not(:last-of-type) {
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 800px) {
    .text {
        padding: 0 1rem 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .text h2 {
        padding: 1.5rem;
        font-size: 2rem;
    }
}