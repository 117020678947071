.stories {
    padding: 0 1.5rem;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.stories ul {
    list-style: none;
    color: white;
    margin-bottom: 2rem;
}

.stories li {
    margin-bottom: 2px;
    display: flex;
    cursor: default;
}

.stories li:hover span:last-of-type {
    background-color: red;
}

.stories li.active span:last-of-type {
    background-color: red;
}

.stories li span {
    padding: .5rem 0;
}

.stories li span:first-of-type {
    background-color: blue;
    text-align: center;
    width: 20rem;
    margin-right: 2px;
    flex-shrink: 0;
}

.stories li span:last-of-type {
    background-color: rgba(0, 0, 0, .55);
    flex-grow: 1;
    padding: 0.5rem 2rem;
}

@media only screen and (max-width: 800px) {
    .stories {
        padding: 0 1rem;
    }

    .stories ul {
        margin-bottom: 1.5rem;
    }

    .stories li span:first-of-type {
        width: 15rem;
    }

    .stories li span:last-of-type {
        padding: 0.5rem 1.5rem;
    }
}

@media only screen and (max-width: 600px) {
    .stories h2 {
        padding: 1.5rem;
        font-size: 2rem;
    }
}