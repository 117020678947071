.button {
    margin: 2px auto;
    background-color: rgba(8, 8, 146, 0.5);
    padding: 1.5rem .5rem;
    color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .55);
    cursor: pointer;
}

.button--main {
    background-color: rgba(8, 8, 146);
    width: 100%;
    height: 5rem;
}

.button--sidebar {
    width: 90%;
    height: 7rem;
}

.button--arrow {
    height: 7rem;
    width: 50%;
    display: inline-block;
    color: yellow;
    font-weight: bold;
}

.button--red {
    background-color: red;
    color: yellow;
    width: 100%;
}

.button:hover {
    color: aqua;
}

.button--red:hover {
    color: white;
}

.active {
    color: aqua;
    box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, .55);
}

.special {
    color: yellow;
    font-weight: bold;
}