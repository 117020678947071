.main {
    padding: 1.5rem;
    overflow: hidden;
}

@media only screen and (max-width: 800px) {
    .main {
        padding: 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .main {
        grid-column: 2 / -1;
    }
}