.App {
  height: 100%;
  width: 100%;
  position: relative;
}

.App::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .6), rgba(255, 255, 255, .6)), url('./Images/bg_1.jpg');
  background-size: cover;
  filter: blur(3px);
  z-index: -1;
}