.sidebar {
    grid-row: 1 / -1;
    padding: 1.5rem 0;
    /* min-width: 12rem; */
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to bottom, rgb(0, 0, 255), #050404);
}

.arrows {
    display: flex;
    width: 90%;
    margin: 0 auto;
}

.toggler {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -4rem;
    height: 40%;
    width: 4rem;
    background-image: linear-gradient(to bottom, rgba(0, 0, 255, .75), rgba(5, 4, 4, .75));
    clip-path: polygon(0 0, 100% 19%, 100% 82%, 0% 100%);
    cursor: default;
}

@media only screen and (max-width: 800px) {
    .sidebar {
        padding: 1rem 0;
    }
}

@media only screen and (max-width: 600px) {
    .sidebar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 15rem;
        transform: translateX(-14.5rem);
        transition: transform .3s;
        z-index: 10;
    }

    .toggler {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 0, 0.5);
        font-size: 2rem;
        z-index: 0;
        writing-mode: vertical-lr;
        text-orientation: sideways;
    }
}